import React from "react";
import { Link } from "react-router-dom";
const CourseCard = ({poster,courseName,courseDescription,targetedExam,link}) => {
  return (
    <div
      className="col-xl-4 col-lg-4 col-md-6 col-sm-12"
      style={{ height: "fit-content" }}
    >
      <div className="course-card  mt-5">
        <div className="course-card-image">
          <Link to={link}>
            <img
              loading="lazy"
              src={poster}
              alt={courseName}
            />
          </Link>
        </div>
        <div>
          <p className="text-2xl font-bold my-2">
            <q className="quotes text-xl text-center">{courseName}</q> 
          </p>
          <u>
            <p className="my-2 font-bold" >{targetedExam}</p>
          </u>
        </div>
        <div >
          <p >
            {courseDescription}
          </p>
          <a className="bg-[yellowgreen] px-5 py-2 rounded-xl mt-2 text-white text-bold" href="./11th-course-jee.html">
            Read More
            
          </a>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
