import React from "react";
import CourseForm from "../component/sections/CourseForm";
import CourseForm2 from "../component/sections/CourseForm2";
import { CourseDetailData } from "../data/CourseDetailData";
import { useParams } from "react-router-dom";

function CoursePage() {
  const {name} = useParams()
  console.log(name)
  const course = CourseDetailData.find((course)=>(course.name===name))
 

  return (
    <div className="px-2 lg:px-20 border py-5 rounded-xl">
   
        <>
          <div className="text-center">
            <h2 className="text-navy h2">
              {course?.courseName}
            </h2>
            <h4 className="text-green h4">
             {course?.targetexam}
            </h4>
            <h5 className="h5">
              (IIT’s &amp; NITs, other Engineering Collages)
            </h5>
            <p>
              {" "}
              {course?.heading}{" "}
            </p>
            {/* <p><strong>
                      Unique Feature of Resonance Indore’s VIKAAS Batch is Intense Personal Attention with All
                      India Level Competitions.
                  </strong></p> */}
            <div className="p-4">
              {course.jeetbatch1&&<p className="text-start">
                {" "}
                <b>{course.jeetbatch1} </b>
                <br />
                <br />
                {course.desc1}
              </p>}
              { course.vikaasbatch2 && <p className="text-start mt-4">
                <b>
                  {" "}
             {course.vikaasbatch2}
                </b>
                <br />
               {course.desc2}
              </p>}
            </div>
            {/* <h4>
      CLASSES COMMENCMENT DATE : 15th April & 9th May 2024
    </h4> */}
          </div>
         <CourseForm data={course.formData} />
          <CourseForm2 data={course.formDataSecond} />
        </>
  
      <h4 class="text-danger text-center">
        Scholarship – Available up to 90%*
      </h4>
    </div>
  );
}

export default CoursePage;
