import React from "react";

const ResultsCard = ({examName,number}) => {
  return (
    <div className="col-md-4 mt-4">
      <div className="counter-container">
        <h3 className="text-navy">{examName}</h3>
        <div className="value" akhi={249366}>
          {number}
        </div>
        <h6 className="text-navy">students qualified since 2009</h6>
      </div>
    </div>
  );
};

export default ResultsCard;
