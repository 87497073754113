import React from "react";

function CourseForm2({data}) {
  return (
    <div >
      <div className="d-flex justify-content-center">
        <table className="table w-full ">
          <tbody className="text-center">
            <tr>
              <th scope="row">COURSE FEE:</th>
              <td>
                <h5>
                 {data.CourseFee}
                </h5>
              </td>
            </tr>
            <tr>
              <th scope="row">FEE PAYMENT Mode:</th>
              <td>
                <h5>{data.FeePaymentMode}</h5>
              </td>
            </tr>
            {data.RegistrationFormFee &&<tr>
              <th scope="row">Registration Form Fee</th>
              <td>
                <h5>{data.RegistrationFormFee}</h5>
              </td>
            </tr>}
            {/* <tr>
          <th scope="row">REGISTRATION FORM FEE:</th>
          <td>
            <h5>– 500/-Rs.Only</h5>
          </td>
        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CourseForm2;
