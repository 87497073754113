import React, { useState } from "react";
import logo from "../assets/image/Reso Logo White.png";
import { Link } from "react-router-dom";

const NavBar = ({setShowForm}) => {
  const [open, setOpen] = useState(false);
  function handleListDropdown(e) {
    if (e.target.classList.contains("navigation-link")) {
      e.target.nextElementSibling.classList.toggle("is-visible");
    }
  }
  function closeMenu() {
    setOpen(false);
  }

  return (
    <nav
      id="site-header"
      className="site-header navigation navigation-justified sticky-top"
    >
      <div className="container">
        <div className="navigation-header">
          <div className="navigation-logo">
            <Link className="site-logo" to="/">
              <img loading="lazy" src={logo} alt="Resonance logo" width={200} />
            </Link>
          </div>
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="navigation-button-toggler"
          >
            <i className="hamburger-icon" />
          </div>
        </div>
        <div
          className={
            open
              ? "navigation-body scroll-momentum is-visible"
              : "navigation-body scroll-momentum"
          }
        >
          <div className="navigation-body-header">
            <div className="navigation-logo">
              <Link className="site-logo" to="/">
                <img
                  loading="lazy"
                  src={logo}
                  alt="Resonance-logo"
                  width={200}
                />
              </Link>
            </div>
            <span
              onClick={() => {
                setOpen(false);
              }}
              className="navigation-body-close-button"
            >
              ✕
            </span>
          </div>
          <ul className="navigation-menu">
            <li className="navigation-item">
              <Link className="navigation-link" to="/" onClick={closeMenu}>
                Home
              </Link>
            </li>
           
            <li className="navigation-item has-submenu">
              <a
                className="navigation-link"
                onClick={(e) => {
                  handleListDropdown(e);
                }}
                href="#"
              >
                About us
                <span className="submenu-indicator" />
              </a>
              <ul
                className="navigation-dropdown navigation-submenu"
                style={{ right: "auto" }}
              >
                <li className="navigation-dropdown-item">
                  <Link
                    className="navigation-dropdown-link"
                    to="/why-resonance"
                    onClick={closeMenu}
                  >
                    Why Resonance?
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
                <li className="navigation-dropdown-item">
                  <Link
                    className="navigation-dropdown-link"
                    to="/why-resonance-indore"
                    onClick={closeMenu}
                  >
                    Why Resonance Indore?
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="navigation-item">
              <Link className="navigation-link" to="/contact" onClick={closeMenu}>
                Contact us
              </Link>
            </li>
            <li className="navigation-item">
              <Link className="navigation-link" to="/result " onClick={closeMenu}>
                Results
              </Link>
            </li>
            <li
              className="navigation-item has-submenu"
              onClick={(e) => {
                handleListDropdown(e);
              }}
            >
              <a className="navigation-link">
                Courses
                <span className="submenu-indicator" />
              </a>
              <ul
                className="navigation-dropdown navigation-submenu"
                style={{ right: "auto" }}
              >
                <h6 className="nav-drop-title">
                  <b>JEE Main + Advanced</b>
                </h6>
                <li className="navigation-dropdown-item">
                  <Link
                    className="navigation-dropdown-link"
                    to="/course/11th-course-jee"
                    onClick={closeMenu}
                  >
                    11 <sup>th</sup> + 12<sup>th</sup> - JEE Main + Advanced
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
                <li className="navigation-dropdown-item">
                  <Link
                    className="navigation-dropdown-link"
                    to="/course/12th-course-jee"
                    onClick={closeMenu}
                  >
                    12<sup>th</sup> - JEE Main + Advanced
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
                <li className="navigation-dropdown-item">
                  <Link
                    className="navigation-dropdown-link"
                    to="/course/Dropper-jee-mains-advance"
                    onClick={closeMenu}
                  >
                    Droppers - JEE Main + Advanced
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
                <h6 className="nav-drop-title">
                  <b>NEET</b>
                </h6>
                <li className="navigation-dropdown-item">
                  <Link
                    className="navigation-dropdown-link"
                    to="/course/11th-Neet"
                    onClick={closeMenu}
                  >
                    11<sup>th</sup> + 12<sup>th</sup> - NEET
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
                <li className="navigation-dropdown-item">
                  <Link
                    className="navigation-dropdown-link"
                    to="/course/12th-Neet"
                    onClick={closeMenu}
                  >
                    12<sup>th</sup> - NEET
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
                <li className="navigation-dropdown-item">
                  <Link
                    className="navigation-dropdown-link"
                    to="/course/Droppers-Neet"
                    onClick={closeMenu}
                  >
                    Droppers - NEET
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
                <h6 className="nav-drop-title">
                  <b>FOUNDATION COURSE</b>
                </h6>
                <li className="navigation-dropdown-item">
                  <Link className="navigation-dropdown-link" onClick={closeMenu}>
                    Foundation - 08<sup>th</sup>
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
                <li className="navigation-dropdown-item">
                  <Link
                    className="navigation-dropdown-link"
                    to="/course/Foundation-09th"
                    onClick={closeMenu}
                  >
                    Foundation - 09<sup>th</sup>
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
                <li className="navigation-dropdown-item">
                  <Link
                    className="navigation-dropdown-link"
                    to="/course/Foundation-10th"
                    onClick={closeMenu}
                  >
                    Foundation - 10<sup>th</sup>
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
              </ul>
            </li>
            <li  className="navigation-item">
              <Link  className="navigation-link  text-[yellowgreen!important] text-indigo-600 font-bold" to={"/resosat"}  onClick={closeMenu}>
                ResoSAT
              </Link>
            </li>
            <li className="navigation-item has-submenu">
              <a
                onClick={(e) => {
                  handleListDropdown(e);
                }}
                className="navigation-link"
                href="#"
              >
                Admissions
                <span className="submenu-indicator" />
              </a>
              <ul
                className="navigation-dropdown navigation-submenu"
                style={{ right: "auto" }}
              >
                <li className="navigation-dropdown-item">
                  <a
                    className="navigation-dropdown-link"
                    href="https://api.whatsapp.com/send?phone=7389934555"
                    target="_blank"
                  >
                    Apply Online
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </a>
                </li>
                <li className="navigation-dropdown-item">
                  <Link
                    className="navigation-dropdown-link"
                    to="./admission&scholarship"
                    onClick={closeMenu}
                  >
                    Scholarships &amp; Rewards
                    <svg
                      className="Resonance-icon arrow-right"
                      width={12}
                      height={10}
                    >
                      <use xlinkHref="#icon-arrow-right" />
                    </svg>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <div className="navigation-body-section navigation-additional-menu">
            <div className="contact-number">
              <a className="lg:text-white text-black font-semibold text-sm-primary" href="tel:7389934555">
                7389934555
              </a>{" "}
              <span>|</span>
              <a className="lg:text-white text-black font-semibold text-sm-primary" href="tel:7389934553">
                7389934553
              </a>
              <span>|</span>
              <a className="lg:text-white text-black font-semibold text-sm-primary" href="tel:7389934552">
                7389934552
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className={open ? "overlay-panel is-visible" : "overlay-panel"}
        style={{ background: "rgba(0, 0, 0, 0.7)" }}
      />
    </nav>
  );
};

export default NavBar;
