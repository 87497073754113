import React from "react";

function CourseForm({data}) {
  console.log(data)
  return (
    <div>
      <table className="table table-striped w-full">
        <tbody className="text-center">
        {data.ClassTimings &&<tr>
            <th scope="row">Class Timings </th>
            <td>
             {data.ClassTimings }
            </td>
          </tr>}
          <tr>
            <th scope="row">Faculty Team</th>
            <td>{data.FacultyTeam}</td>
          </tr>
          <tr>
            <th scope="row">Batch size</th>
            <td>{data.Batchsize}</td>
          </tr>
          <tr>
            <th scope="row">Test</th>
            <td>
              {data.Test}
            </td>
          </tr>
          {data.SelfStudyZone &&<tr>
            <th scope="row">Self Study Zone</th>
            <td>
             {data.SelfStudyZone}
            </td>
          </tr>}
          {data. SubjectsCovered &&<tr>
            <th scope="row"> Subjects Covered</th>
            <td>{data. SubjectsCovered}</td>
          </tr>}
          <tr>
            <th scope="row">Doubt Classes</th>
            <td>{data.DoubtClasses}</td>
          </tr>
          <tr>
            <th scope="row">Study Material</th>
            <td>
              {data.StudyMaterial}
            </td>
          </tr>
          <tr>
            <th scope="row">Library</th>
            <td>
              {data.Library}
            </td>
          </tr>
          { data.SchoolAdmissionFacility &&<tr>
            <th scope="row">School Admission Facility</th>
            <td>{data.SchoolAdmissionFacility}</td>
          </tr>}
          
          {data.MedicalFacility &&<tr>
            <th scope="row">Medical Facility</th>
            <td>
             {data.MedicalFacility}
            </td>
          </tr>}
          { data.HostelFacility &&<tr>
            <th scope="row">Hostel Facility</th>
            <td>{data.HostelFacility}</td>
          </tr>}
          {data. MotivationalSessions &&<tr>
            <th scope="row"> Motivational Sessions</th>
            <td>{data. MotivationalSessions}</td>
          </tr>}
          {data. Medium &&<tr>
            <th scope="row"> Medium</th>
            <td>{data. Medium}</td>
          </tr>}
          
          {data.HomeWorkDiscussion &&<tr>
            <th scope="row">Home Work Discussion</th>
            <td>{data.HomeWorkDiscussion}</td>
          </tr>}
          { data.Counseling &&<tr>
            <th scope="row">Counseling</th>
            <td>{data.Counseling}</td>
          </tr>}
        </tbody>
      </table>
    </div>
  );
}

export default CourseForm;
