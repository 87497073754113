import React, { useEffect, useRef, useState } from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";
import { Outlet } from "react-router-dom";
import EnquiryForm from "../EnquiryForm";
import ResoSatRegisterModal from "../ResoSatRegisterModal";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const [showForm, setShowForm] = useState(false);
  const mainRef = useRef();
  const location = useLocation();
  function closeDropdowns() {
    let dropers = document.querySelectorAll(".is-visible");
    dropers.forEach((drop) => drop.classList.remove("is-visible"));
  }
  console.log(location.pathname.includes("resosat"));

  useEffect(() => {
    let popup = setTimeout(() => {
      setShowForm(true);
    }, 500);
    if (location.pathname.includes("resosat")) {
      clearTimeout(popup);
    }
  }, []);

  useEffect(() => {
    mainRef.current.scrollIntoView({ behavior: "instant", block: "start" });
  }, [location]);

  return (
    <div ref={mainRef}>
      <NavBar setShowForm={setShowForm} />
      <div onMouseEnter={closeDropdowns}>
        {" "}
        <Outlet />
      </div>
      {/* <main>
            {children}
        </main> */}
      <Footer />
      {showForm && (
        <div className="h-screen z-[1000] flex fixed top-0 w-screen bg-[#1a1a1896] z-50">
          {/* <EnquiryForm setClose={setShowForm} />  */}
          <ResoSatRegisterModal setClose={setShowForm} />
        </div>
      )}
    </div>
  );
};

export default Layout;
