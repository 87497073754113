import React from "react";
import { HallOfFameImages } from "../../data/hallOfFameData";
import imagep from "../../assets/image/resonet-scholorship-exam-i838.png"


const JEEResultSection = () => {
  return (
    <section className="mt-16 mx-3  rounded-tr-[100px] rounded-tl-[100px] bg-[#aaee5c] py-4">
      <div>
        <h1 className="font-bold text-4xl text-white my-5 uppercase text-center">
          Resonance Indore Hall Of Fame
        </h1>
        <div className="my-4 grid lg:grid-cols-6 xl:grid-cols-8 md:grid-cols-6 grid-cols-4   content-center gap-8 mx-auto w-11/12 h-fit px-4 py-2">
          {HallOfFameImages.map((image) => (
            <div className="cursor-pointer hover:scale-125 duration-500">
              <img src={`${image}`} alt="alumniImage" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default JEEResultSection;
