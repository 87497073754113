import React from "react";
import ResultsCard from "../Cards/ResultsCard";

const cardDetail = [
  {
    examName: "JEE ( Advance )",
    qualified_students: 51221,
  },
  { examName: "JEE ( Main )", qualified_students: 249366 },
  {
    examName: "NEET ( UG )",
    qualified_students: 20832,
  },
];

const ResultCardSection = () => {
  return (
    <div className="my-5">
      <h3  className="h3 text-center">Result</h3>
      <div className="row" style={{ width: "80vw", margin: "auto" }}>
        {cardDetail.map((card) => (
          <ResultsCard
            examName={card.examName}
            number={card.qualified_students}
          />
        ))}
      </div>
    </div>
  );
};

export default ResultCardSection;
