import React from "react";
import whyResonanceImage from "../assets/image/About resonance image 570X386.jpg";

import WhyResonanceimages from "../assets/image/individual counsling session.jpg";

function WhyResonanceIndorePage() {
  return (
    <div >
      <div className="main-content-wrapper lg:px-20 px-8">
        {/* why-choose-banner */}
        <section className="large-padding section-anime-js coaching" id="intro">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4 mt-md-4">
                <img
                  loading="lazy"
                  className="rounded-tl-[40px] rounded-br-[40px]"
                  src={whyResonanceImage}
                  alt="Why Resonance Photo"
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                <header className="Resonance-module Resonance-heading mb-4">
                  <div className="title-text-wrap">
                    <h1 className="heading-title  text-navy element-anime-fadeInUp-js h2">
                      Resonance Indore - Best IIT JEE/Neet Coaching
                    </h1>
                  </div>
                </header>
                <ul >
                  <strong>
                    <li>
                      Expert, Experienced &amp; Trained faculty team from KOTA
                      only.
                    </li>
                    <li>Result oriented attention for every student.</li>
                    <li>
                      Intense personal attention to every student with batch
                      size of 40-45 students only.
                    </li>
                    <li>
                      Time to time Individual counselling session for
                      motivation.
                    </li>
                    <li>Centralized course planner</li>
                    <li>All India level competition benefits.</li>
                  </strong>
                </ul>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
                <p dir="ltr">
                  Resonance Indore An Exemplary in the field of quality parallel
                  education, which has set high standards for JEE – (Main +
                  Advanced) &amp; Pre - Medical preparation. Indore study centre
                  is redefining what preparatory education is, the Faculty, the
                  ethics, the Study Material, the Staff- all work relentlessly
                  for optimising the output from a student, which is paramount
                  for the assured success in the ferocious competition of
                  IIT-JEE / NEET.
                </p>
                <p>
                  In a short span of time it has become a household name and
                  Institute of choice for preparation of big competitive exams
                  like JEE (Main &amp; Advanced) and Pre-Medicals (NEET). There
                  was a much felt need to give the students an edge at a younger
                  age, to make children reign in the competitive world. With the
                  vision to improve academics in young children, Resonance's Pre
                  - foundation Career care program or PCCP was incepted, and
                  there has been no looking back since then. There are arrays of
                  performances, in both yearlong contact classroom program or
                  YCCP and PCCP. Reso Indore is producing toppers from Indore in
                  JEE-main or Advanced and giving Excellent Ratio of qualifiers
                  for both IIT-JEE (Advanced) and NEET.
                </p>
                <p>
                  The exams like JEE (Main + Advanced) attracts huge number of
                  applicants, and thus offers enormous competition in academics.
                  The quality training and uncompromised approach to deliver the
                  highest standards in studies, has earned the Institute the
                  reputation of most sought after institute in the segment.
                  Reso’s phenomenal growths in both quality &amp; quantity of
                  results speak volumes about our approach and excellence we
                  impart. The glory has spread outside Indore and M.P. and
                  students from far flung places also come to sharpen their
                  skills and shape their future. The Journey so far has been
                  very exciting and is evident from Leaps and Bounds growth both
                  in selections and enrolments.
                </p>
              </div>
              <div className="col-lg-12 mt-3">
                <h4 className="style01 h4 text-navy">
                  {" "}
                  Teaching Methodology at Resonance Indore
                </h4>
                <p>
                  Resonance has a highly systematic and standardized system of
                  teaching. Our study material, course planning, testing system,
                  timetable, performance monitoring system etc. are all
                  pre-defined and well set. All systems work according to these
                  rules and deliver a uniform and high-quality learning
                  experience to all students
                </p>
              </div>
              <div className="row student-mentoring mt-4">
                <div className="col-lg-6 ">
                  <img
                    className="System"
                    src={WhyResonanceimages}
                    alt="academic divisions"
                  />
                </div>
                <div className="col-lg-6 mt-4 ">
                  <h3>Student Mentoring System</h3>
                  <p>Course &amp; Lecture Planning</p>
                  <p>Teaching Methodology</p>
                  <p>Lecture Notes Preparation</p>
                  <p>Faculty Productivity Management</p>
                  <p>
                    Study Material Development &amp; Question Bank Management
                  </p>
                  <p>Test Paper Preparation</p>
                  <p>Regular Class Management</p>
                  <p>Student's Performance Management</p>
                  <p>Academic Quality Control &amp; Auditing</p>
                  <p>Academic Research &amp; Development</p>
                  <p>Orientation &amp; Motivation Classes</p>
                  <p>Special Classes for Performance Improvement</p>
                  <p>Faculty Discipline &amp; Performance Management</p>
                  <p>Online Test Progrom</p>
                  <p>Computer Lab &amp; Self Study Room</p>
                </div>
              </div>
              <p className="text-navy font-weight-bold">
                * We understand the importance of different components, tools,
                and techniques of training, which are necessary for results and
                we term this system as "The RESO Way"
              </p>
              <div className="col-lg-6 col-md-12 col-sm-12 mt-4">
                <h5 className="h5">Academic Team (Faculty)</h5>
                <p>Teacher understands how to make you LEARN.</p>
                <hr />
                <h5>Regular Classes (RCs)</h5>
                <p>
                  Fundamental learning through rigorous &amp; enriched classroom
                  experience.
                </p>
                <hr />
                <h5> Daily Practice Problems (DPPs)</h5>
                <p>Daily Practice for strengthening the daily learning.</p>
                <hr />
                <h5> Practice Booklet (Sheets)</h5>
                <p>
                  Experience and knowledge in a well written form. More practice
                  towards perfection.
                </p>
                <hr />
                <h5>Doubt Classes (DCs)</h5>
                <p>For individual attention and problem solving.</p>
                <hr />
                <h5> Periodic Assessment Tests (PATS)</h5>
                <p>Assessment through online &amp;&amp; offline tests.</p>
                <hr />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 mt-4">
                <h5>Academic Research &amp; Development (R&amp;D)</h5>
                <p>Developing new teaching aids and questions.</p>
                <hr />
                <h5>Academic Administration and Management</h5>
                <p> Managing entire learning experience.</p>
                <hr />
                <h5> Online Learning Platform</h5>
                <p>Facility of Online Classes &amp; Recorded Lectures.</p>
                <hr />
                <h5> Co-academic Arrangements</h5>
                <p>Timely support &amp; stress free learning.</p>
                <hr />
                <h5>Concept Building Classes.</h5>
                <p>
                  Theory of certain important topics is taught after the course
                  completion of Class XI to XII Moving Students along with their
                  regular Tests &amp; Practice.
                </p>
                <hr />
                <h5> Clinic Classes</h5>
                <p>
                  Special Classes arranged for weak and mis Topics to enhance
                  students performance durin the session.
                </p>
                <hr />
              </div>
            </div>
          </div>
        </section>
        {/* why-choose-banner */}
      </div>
    </div>
  );
}

export default WhyResonanceIndorePage;
