import React from "react";
import scholarshipImage from "../assets/image/Scholarships.png"
import whyScholarshipImg from "../assets/image/Why Scholarship.png"
import eligibility from "../assets/image/Eligibility.png"
import categoryImg from "../assets/image/Categories-removebg-preview.png"
import performanceImg from "../assets/image/Performance in Scholarship Talent Reward Examination (1).jpg"
import applicableImg from "../assets/image/Applicable For.png"

const admissionPage = () => {
  return (
    <div className="container mt-4 mb-4">
      <div className="row">
        <div className="col-12"  style={{ textAlign: "center" }}>
          <img loading="lazy" className="m-auto" src={scholarshipImage} alt="Scholarships" />
        </div>
        <div className="col-12 mt-4">
          <div className="row">
            <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
              <img
                loading="lazy"
                height="500px"
                className="m-auto"
                width="100%"
                src={whyScholarshipImg}
                alt="Why Scholarship"
              />
            </div>
            <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
              <img
                loading="lazy"
                height="500px"
                className="m-auto"
                width="100%"
                src={eligibility}
                alt="Eligibility"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12" style={{ textAlign: "center" }}>
          <img
            loading="lazy"
            className="m-auto"
            src={categoryImg}
            alt="Categories"
          />
        </div>
        <div className="col-12 mt-4">
          <img
            loading="lazy"
            className="m-auto"
            src={performanceImg}
            alt="Categories"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12" style={{ textAlign: "center" }}>
          <img  loading="lazy" className="m-auto" src={applicableImg} alt="Categories" />
        </div>
      </div>
    </div>
  );
};

export default admissionPage;
