import React from "react";

function ContactPage() {
  return (
    <>
      <div className="lg:px-40 ">
        <section className=" ptb-120 position-relative section-image-bg-grey large-padding">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div
                  className="section-heading aos-init aos-animate "
                  data-aos="fade-up"
                >
                  <h4 className="text-navy h4">Have any queries?</h4>
                  <h2 className="text-green h2">Get in Touch Today!</h2>
                  <h5 className="text-navy h5">9am to 8pm IST.</h5>
                </div>
                {/* <div>
                  <h2 className="text-lg font-medium text-white">Campuses</h2>
                  <div className="mt-4">
                    <p className="font-bold">
                      <b>SOUTH TUKOGANJ CAMPUS - INDORE:</b>
                    </p>
                    <p className="mt-1">
                      Ground Floor, UV House, Near Jal Sabhagrah, South
                      Tukoganj, Indore (Madhya Pradesh) - 452001
                      <br />
                      np_info_indore@resonance.ac.in (0731) 4005695, (+91)
                      7389934555/53
                    </p>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.235030474353!2d75.87616867476142!3d22.71950402757113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd3dd801b06d%3A0xd32690b1181ca251!2sU%20V%20House%2C%20S%20Tukoganj%20Street%201%2C%20South%20Tukoganj%2C%20Indore%2C%20Madhya%20Pradesh%20452001!5e0!3m2!1sen!2sin!4v1702979403852!5m2!1sen!2sin"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      width="100%"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </div>
                  <div className="mt-4">
                    <p className="font-bold">
                      <b>ANNAPURNA CAMPUS - INDORE:</b>
                    </p>
                    <p className="mt-1">
                      1st Floor, Meera's, Footi-Kothi Square, Near Domino's
                      Sudama Nagar, Indore (Madhya Pradesh) – 452009
                      <br />
                      np_info_indore@resonance.ac.in (0731) 4046267, (+91)
                      7389934552/53/55
                    </p>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.9177960196216!2d75.8264135747606!3d22.694103878507107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fc35be6c3e95%3A0x97301562a863b882!2sResonance%20Indore%20-%20Annapurna%20Center!5e0!3m2!1sen!2sin!4v1702979675511!5m2!1sen!2sin"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      width="100%"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  </div>
                </div> */}

                <div className="row mt-5  pb-5">
                  <div
                    className="col-sm-6 mb-md-0 w-fit mb-lg-0 aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay={50}
                  >
                    <div className="icon-box d-inline-flex gap-x-5 rounded-circle bg-primary-soft">
                      <i
                        className="fa-solid fa-phone-volume text-3xl lg:text-5xl text-navy"
                        aria-hidden="true"
                      />
                      <h5 className="h5">Call Us</h5>
                    </div>
                    <div className="contact-info">
                      <p>Call for support</p>
                      <a
                        href="tel:7389934555"
                        className="read-more-link text-decoration-none"
                      >
                        <i
                          className="fas fa-phone me-2 text-green"
                          aria-hidden="true"
                        />{" "}
                        7389934555
                      </a>{" "}
                      <br />
                      <a
                        href="tel:7389934553"
                        className="read-more-link text-decoration-none"
                      >
                        <i
                          className="fas fa-phone me-2 text-green"
                          aria-hidden="true"
                        />{" "}
                        7389934553
                      </a>{" "}
                      <br />
                      <a
                        href="tel:7389934552"
                        className="read-more-link text-decoration-none"
                      >
                        <i
                          className="fas fa-phone me-2 text-green"
                          aria-hidden="true"
                        />{" "}
                        7389934552
                      </a>
                      <br />
                      <a
                        href="tel:07314005695"
                        className="read-more-link text-decoration-none"
                      >
                        <i
                          className="fas fa-phone me-2 text-green"
                          aria-hidden="true"
                        />{" "}
                        07314005695
                      </a>{" "}
                      <br />
                      {/* <a href="tel:7389934555" class="read-more-link text-decoration-none"><i
                                      class="fas fa-phone me-2 text-green"></i> 7389934555</a> */}
                    </div>
                  </div>
                  <div>
                    <div className="icon-box d-inline-flex gap-x-5 rounded-circle bg-danger-soft">
                      <i
                        className="fas fa-headset text-danger text-2xl lg:text-5xl"
                        aria-hidden="true"
                      />
                      <h5 className="h5">Chat Us</h5>
                    </div>
                    <div className="contact-info">
                     
                      <a
                        href="https://api.whatsapp.com/send?phone=7389934555"
                        target="_blank"
                        className="read-more-link text-decoration-none"
                      >
                        <i
                          className="fas fa-comment me-2 text-green"
                          aria-hidden="true"
                        />{" "}
                        Send Message
                      </a>{" "}
                      <br />
                      <a
                        href="mailto:np_info_indore@resonance.ac.in"
                        target="_blank"
                        className="read-more-link text-decoration-none"
                      >
                        <i
                          className="fa-regular fa-envelope text-green"
                          aria-hidden="true"
                        />{" "}
                        Send Mail
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-7 col-md-12">
                <div
                  className="contact-us register-wrap p-3 lg:p-5 shadow rounded-custom position-relative aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={150}
                >
                  <form
                    id="formEnquiry"
                    className="register-form position-relative z-5"
                  >
                    <h3 className="mb-5 h3 fw-medium">
                      We'll be in touch as soon as possible.
                    </h3>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            required=""
                            placeholder=" Full Name"
                            id="fullname"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-group mb-3">
                          <input
                            type="number"
                            className="form-control"
                            required=""
                            placeholder="Number"
                            id="number"
                            pattern="[0-9]{10}"
                            title="Please enter a 10-digit phone number"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 ">
                        <div className="input-group mb-3">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Alternate No."
                            id="altnumber"
                            pattern="[0-9]{10}"
                            title="Please enter a 10-digit phone number"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-group mb-3">
                          <select
                            className="form-control"
                            id="course"
                            required=""
                          >
                            <option value="">-Target Examination-</option>
                            <option value="JEE(Main + Advanced)">
                              JEE(Main + Advanced)
                            </option>
                            <option value="JEE(Main)">JEE(Main)</option>
                            <option value="NEET">NEET</option>
                            <option value="Foundation Course">
                              Foundation Course
                            </option>
                            <option value="CUET">CUET</option>
                            <option value="8th">8th</option>
                            <option value="9th">9th</option>
                            <option value="10th">10th</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Class"
                            id="class"
                            title="Please enter your class"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-group mb-3">
                          <select
                            className="form-control"
                            id="city"
                            required=""
                          >
                            <option>--select city--</option>
                            <option value="Indore">Indore</option>
                            <option value="Ujjain">Ujjain</option>
                            <option value="Bhopal">Bhopal</option>
                            <option value="Dewas">Dewas</option>
                            <option value="Khargone">Khargone</option>
                            <option value="Barwaha">Barwaha</option>
                            <option value="Dhar">Dhar</option>
                            <option value="Dhamnod">Dhamnod</option>
                            <option value="Maheshwar">Maheshwar</option>
                            <option value="Sanwer">Sanwer</option>
                            <option value="Depalpur">Depalpur</option>
                            <option value="Hatod">Hatod</option>
                            <option value="Betma">Betma</option>
                            <option value="Manpur">Manpur</option>
                            <option value="Sanawad">Sanawad</option>
                            <option value="Vadodara">Vadodara</option>
                            <option value="Jabalpur">Jabalpur</option>
                            <option value="Sagar">Sagar</option>
                            <option value="Balaghat">Balaghat</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Mumbai">Mumbai</option>
                            <option value="Pune">Pune</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-group mb-3">
                          <textarea
                            className="form-control"
                            placeholder="Tell us more about your query."
                            id="message"
                            style={{ height: 120 }}
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          type="submit"
                          className="btn button--primary mt-4 d-block w-100"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="Resonance-stunning-header h-fit"
          style={{ padding: "50px 0 !important", backgroundColor: "#313759",marginBottom:"24px" }}
        >
          <div className="container">
            <div className="stunning-header-content align-center">
              <h1 className="h1 page-title text-white">Campuses</h1>
              <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-x-5">
                <div className="mt-4 h-[50vh]">
                  <p className="font-bold text-white">
                    <b>SOUTH TUKOGANJ CAMPUS - INDORE:</b>
                  </p>
                  <p className="my-2 text-white">
                    Ground Floor, UV House, Near Jal Sabhagrah, South Tukoganj,
                    Indore (Madhya Pradesh) - 452001
                    <br />
                    np_info_indore@resonance.ac.in (0731) 4005695, (+91)
                    7389934555/53
                  </p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.235030474353!2d75.87616867476142!3d22.71950402757113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd3dd801b06d%3A0xd32690b1181ca251!2sU%20V%20House%2C%20S%20Tukoganj%20Street%201%2C%20South%20Tukoganj%2C%20Indore%2C%20Madhya%20Pradesh%20452001!5e0!3m2!1sen!2sin!4v1702979403852!5m2!1sen!2sin"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    width="100%"
                    height="60%"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
                <div className="mt-4 h-[50vh]">
                  <p className="font-bold text-white">
                    <b>ANNAPURNA CAMPUS - INDORE:</b>
                  </p>
                  <p className="my-2 text-white">
                    1st Floor, Meera's, Footi-Kothi Square, Near Domino's Sudama
                    Nagar, Indore (Madhya Pradesh) – 452009
                    <br />
                    np_info_indore@resonance.ac.in (0731) 4046267, (+91)
                    7389934552/53/55
                  </p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.9177960196216!2d75.8264135747606!3d22.694103878507107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fc35be6c3e95%3A0x97301562a863b882!2sResonance%20Indore%20-%20Annapurna%20Center!5e0!3m2!1sen!2sin!4v1702979675511!5m2!1sen!2sin"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    width="100%"
                    height="60%"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ContactPage;
