import React from "react";
import thankyou from "../assets/image/icon1.jpeg"
import { Link } from "react-router-dom";

const Thankyou = ({message}) => {
  const loginURL = "https://resosat.resonanceindore.com"
  return (
    <div className="text-center rounded-xl flex-col items-center justify-center w-[95vw] lg:w-[50vw] m-auto bg-white p-6" >
      <img className="mx-auto" src={thankyou} alt="" width="100px" />
      <h1 className="thankyou h1">THANK YOU!</h1>
      <p className="font-bold text-2xl" > {message}</p>
     <a href="/"> <h3 className="pt-5 text-2xl  text-blue-600 hover:scale-95 duration-250 cursor-pointer underline">Go To HOME</h3></a><br/>
     <a href={loginURL} target="blank"> <h3 className="mt-4 text-2xl bg-green-600  text-white px-3 py-1 font-bold cursor-pointer shadow-xl rounded-xl hover:scale-95 duration-250 cursor-pointer mx-6  ">LOGIN</h3></a>
    </div>
  );
};

export default Thankyou;
