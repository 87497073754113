import React from "react";
import MissionSection from "../component/sections/MissionSection";

const WhyResonancePage = () => {
  return (
    <div className="lg:px-28 px-8 ">
      <MissionSection />

      <div className="container margin_60_35">
        {/* <div class="main_title_2 animator fadeInUp animated ">
      <h4 class="style01">Why choose Resonance</h4>
      <span><em></em></span>
      <p></p>
  </div> */}
        <div className="row justify-content-between">
          <div className="col-lg-12 animator animated">
            <h5 className="heading-title font-weight-bold line-bottom mt-0 mb-30 mt-sm-30">
              System-based Organization
            </h5>
            {/* <div class="heading-decoration element-anime-fadeInUp-js"
          style="transform: translateY(0px); opacity: 1;"></div> */}
            <ul className="bullets box-checked">
              <li>
                Leadership: Experienced, Competent, Qualified (IITian) &amp;
                Academician
              </li>
              <li>
                Core Team: Group of Academicians (Mostly IITians) &amp; able
                Administrators
              </li>
              <li>
                Defined Processes and Structured Roles &amp; Responsibilities
              </li>
              <li>Planned Academic Management &amp; Curriculum Execution</li>
              <li>Student-centric &amp; Performance Oriented Approach</li>
            </ul>
          </div>
          <div
            className="col-lg-12 animator wow fadeInLeft animated"
            style={{ visibility: "visible", animationName: "fadeInLeft" }}
          >
            <h5 className="heading-title font-weight-bold line-bottom mt-0 mb-30 mt-sm-30">
              Preparatory Programmes for Various Examinations
            </h5>
            {/* <div class="heading-decoration element-anime-fadeInUp-js"></div> */}
            <ul className="bullets box-checked">
              <li>Joint Entrance Examination JEE(MAIN + Advanced)</li>
              <li>Joint Entrance Examination JEE(MAIN)</li>
              <li>All India Pre-Medical Test (NEET (AIPMT))</li>
              <li>All India Institute Of Medical Science (AIIMS)</li>
              <li>
                Scholarship/T alent Search Examinations like NTSE, KVPY &amp;
                Olympiads
              </li>
              <li>
                Other National/State level Engineering/Medical Entrance
                Examinations (along with JEE (Main + Advanced)/JEE (Main) &amp;
                NEET(AIPMT)/AIIMS respectively)
              </li>
              <li>CA/CS, CLAT</li>
            </ul>
          </div>
          <div
            className="col-lg-12 animator wow fadeInLeft animated"
            style={{ visibility: "visible", animationName: "fadeInLeft" }}
          >
            <h5 className="heading-title font-weight-bold line-bottom mt-0 mb-30 mt-sm-30">
              Student-centric Academic Programmes/Courses
            </h5>
            {/* <div class="heading-decoration element-anime-fadeInUp-js"
          style="transform: translateY(0px); opacity: 1;"></div> */}
            <ul className="bullets box-checked">
              <li>
                Classroom Contact Programmes (CCPs): Year-long, Short-term &amp;
                fast-track Programmes for JEE (Main + Advanced), JEE (Main)
                &amp; NEET(AIPMT)/AIIMS for the students of Classes - XI, XII
                &amp; XII+
              </li>
              <li>
                Pre-foundation Career Care Programmes (PCCPs) for NTSE &amp;
                Olympiads for the students of Classes - V to X
              </li>
              <li>
                Distance Learning Programmes (DLPs) for the students of Classes
                - VI to XII &amp; XII+ for JEE (Main + Advanced), JEE (Main),
                NEET(AIPMT)/AIIMS &amp; NTSE etc.
              </li>
              <li>
                e-Learning Programmes (eLPs) for the students of Classes - V to
                XII &amp; XII+ for JEE (Main + Advanced), JEE (Main),
                NEET(AIPMT)/AIIMS &amp; NTSE etc.
              </li>
              <li>
                Coverage of respective class Board Syllabus (mostly CBSE based
                on NCERT) apart from the preparation of Competitive Examinations
              </li>
            </ul>
          </div>
          <div
            className="col-lg-12 animator wow fadeInLeft animated"
            style={{ visibility: "visible", animationName: "fadeInLeft" }}
          >
            <h5 className="heading-title font-weight-bold line-bottom mt-0 mb-30 mt-sm-30">
              Human Resources
            </h5>
            {/* <div class="heading-decoration element-anime-fadeInUp-js"></div> */}
            <ul className="bullets box-checked">
              <li>
                One of the largest pools of full-time faculty members in the
                coaching sector (500+)
              </li>
              <li>
                More than 25% Faculty Members are IITian (qualified JEE (Main +
                Advanced)) in JEE (Main + Advanced) Division
              </li>
              <li>
                More than 50% Faculty members are having teaching experience of
                more than 5 years in the respective target examination coaching
              </li>
              <li>
                Superior Faculty Stability Factor (less % turnover) compared to
                the coaching education sector
              </li>
              <li>
                Qualified, Competent, Experienced, Caring &amp; Accountable
                Support Staff (non-academics)
              </li>
            </ul>
          </div>
          <div
            className="col-lg-12 animator wow fadeInLeft animated"
            style={{ visibility: "visible", animationName: "fadeInLeft" }}
          >
            <h5 className="heading-title font-weight-bold line-bottom mt-0 mb-30 mt-sm-30">
              Teaching Methodology
            </h5>
            {/* <div class="heading-decoration element-anime-fadeInUp-js"></div> */}
            <ul className="bullets box-checked">
              <li>
                Tried, Tested &amp; Trusted Teaching Methodology of Kota System
                is followed
              </li>
              <li>
                Application-based Conceptual &amp; Effective Classroom Teaching
                (For CCPs)
              </li>
              <li>
                Fundamental learning-based &amp; well-researched Study Material
                with Comprehensive Syllabus coverage
              </li>
              <li>
                Personalized Doubt Removal Classes to enhance ‘individual’
                learning
              </li>
              <li>
                Innovative &amp; Examination Pattern-based Periodic Testing
                &amp; Assessment System
              </li>
            </ul>
          </div>
          <div
            className="col-lg-12 animator wow fadeInLeft animated"
            style={{ visibility: "visible", animationName: "fadeInLeft" }}
          >
            <h5 className="heading-title font-weight-bold line-bottom mt-0 mb-30 mt-sm-30">
              Learning Environment
            </h5>
            {/* <div class="heading-decoration element-anime-fadeInUp-js"></div> */}
            <ul className="bullets box-checked">
              <li>Disciplined Execution of Course Planning &amp; Syllabus</li>
              <li>
                Interactive Approach to promote &amp; solve/clear the
                doubts/concepts
              </li>
              <li>
                Continuous Performance Evaluation &amp; Remedial measures to
                improve performance
              </li>
              <li>Continuous Competitive Benchmarking at National-level</li>
              <li>
                Supportive co-academic environment makes the learning conducive
              </li>
            </ul>
          </div>
          <div
            className="col-lg-12 animator wow fadeInLeft animated"
            style={{ visibility: "visible", animationName: "fadeInLeft" }}
          >
            <h5 className="heading-title font-weight-bold line-bottom mt-0 mb-30 mt-sm-30">
              Unparallel Growth/Enrollments
            </h5>
            {/* <div class="heading-decoration element-anime-fadeInUp-js"></div> */}
            <ul className="bullets box-checked">
              <li>
                Ever growing trust of students &amp; parents as a 'sincere'
                institute
              </li>
              <li>More than 4,40,000+ students enrolled since 2001</li>
              <li>
                More than 3,00,000+ students enrolled in Classroom contact
                Programmes since 2001
              </li>
              <li>
                More than 1,40,000+ students enrolled in Distance Learning
                Programmes since 2001
              </li>
              <li>
                More than 90000+ students are enrolled in academic session
                2015-16
              </li>
            </ul>
          </div>
          <div
            className="col-lg-12 animator wow fadeInLeft animated"
            style={{ visibility: "visible", animationName: "fadeInLeft" }}
          >
            <h5 className="heading-title font-weight-bold line-bottom mt-0 mb-30 mt-sm-30">
              Unprecedented Success Story/Results
            </h5>
            {/* <div class="heading-decoration element-anime-fadeInUp-js"></div> */}
            <ul className="bullets box-checked">
              <li>
                The cumulative results in JEE (Main + Advanced) since 2002 are
                unmatched by any other 'contemporary' institute in JEE (Main +
                Advanced) Coaching segment. The 'only' institute in the country
                which has put up its complete results in 'JEE (Main + Advanced)'
                on its website with details of each students, course/programme
                wise, All India Rank (AIR) and Category wise
              </li>
              <li>
                Unprecedented Success in JEE (Main + Advanced) since 2002
                (Total: 47285 Selections; Classroom: 31748; Distance Learning:
                15537) including 76 students in Top-100 (AIR) from Yearlong
                Classroom Contact Programmes (YCCPs)
              </li>
              <li>
                More than 161459 + selections in JEE (Main) &amp; other National
                Level Engineering Entrance Examinations (NLEEEs) since 2002
                (approx.)
              </li>
              <li>
                16759 Selections in NEET since 2012 (classroom : 10512 / Dlpd+
                Elpd: 6247).
              </li>
              <li>
                494 Selections in AIIMS since 2012 (classroom : 163 / Dlpd+
                Elpd: 218 approx).
              </li>
              <li>
                Consistent Selections in National Talent Search Examination
                (NTSE), Kishore Vaigyanik Protsahan Yojana (KVPY) &amp;
                International Olympiads (IOs)
              </li>
            </ul>
          </div>
          <div
            className="col-lg-12 animator wow fadeInLeft animated"
            style={{ visibility: "visible", animationName: "fadeInLeft" }}
          >
            <h5 className="heading-title font-weight-bold line-bottom mt-0 mb-30 mt-sm-30">
              Student Welfare Programmes (SWPs)
            </h5>
            {/* <div class="heading-decoration element-anime-fadeInUp-js"></div> */}
            <ul className="bullets box-checked">
              <li>
                Micro-Macro Level Performance Analysis &amp; Communication
              </li>
              <li>
                Students Satisfaction Surveys (SSS) about Academic &amp; Non-
                academics
              </li>
              <li>
                Parents-Teacher Meeting (PTM)/Parents-Resonance Meeting (PRM)
              </li>
              <li>
                Grievance Redressal, Assessment &amp; Management System (GRAMS)
              </li>
              <li>
                Counseling (Career , Exams &amp; Health) &amp; Motivation
                Programmes (CMPs)
              </li>
            </ul>
          </div>
          <div
            className="col-lg-12 animator wow fadeInLeft animated"
            style={{ visibility: "visible", animationName: "fadeInLeft" }}
          >
            <h5 className="heading-title font-weight-bold line-bottom mt-0 mb-30 mt-sm-30">
              Nation-wide Network
            </h5>
            <ul className="bullets box-checked">
              <li>01 Resonance Corporate/Head Office at Kota.</li>
              <li>
                28 Study Centres across the country for Classroom contact
                Programmes (CCPs)
              </li>
              <li>01 BASE Main Branch.</li>
              <li>19 BASE Study Center (BSC).</li>
              <li>
                33 Test Centres in Major Cities for Distance Learning programmes
                &amp; All India Test Series.
              </li>
              <li>09 Live Learning Programme(LLPs) Centers.</li>
              <li>
                Nation-wide reach through Distance Learning Programmes (DLPs)
              </li>
              <li>Global reach through e-Learning Programmes (eLPs)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyResonancePage;
