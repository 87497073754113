import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./component/NavBar";
import HomePage from "./pages/HomePage";
import WhyResonancePage from "./pages/WhyResonancePage";
import Layout from "./component/layout/Layout";
import WhyResonanceIndorePage from "./pages/WhyResonanceIndorePage";
import ContactPage from "./pages/ContactPage";
import CoursePage from "./pages/CoursePage";
import ResultPage from "./pages/ResultPage";
import AdmissionPage from "./pages/admissionPage";
import ResosatRegistrationForm from "./pages/ResosatRegistrationForm";
import Thankyou from "./component/Thankyou";
import ResoSatPage from "./pages/ResoSATPage";
import OtpVerification from "./component/OtpVerifier";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="/resosat" element={<ResoSatPage/>} />
            <Route path="/test" element={<OtpVerification/>} />
            <Route path="/resosat-register" element={<ResosatRegistrationForm/>} />
            <Route path="/thankyou" element={<div className="h-[85vh] bg-white flex items-center"><Thankyou message={"Your enquiry has been submited successfully. We Will Get Back To You Shortly!"}/></div>} />
            <Route path="/why-resonance" element={<WhyResonancePage />} />
            <Route
              path="/why-resonance-indore"
              element={<WhyResonanceIndorePage />}
            />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/admission&scholarship" element={<AdmissionPage/>} />
            <Route path="/course/:name" element={<CoursePage />} />
            <Route path="/result" element={<ResultPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
