import React from "react";
import poster from "../assets/image/carouselImages/website Banner-third.jpg";
import JEEResultSection from "../component/sections/JEEResultSection";
import NEETResultSection from "../component/sections/NEETResultSection";
function ResultPage() {
  return (
    <div>
      <div>
        {/*1st Poster*/}
        <img loading="lazy" src={poster} alt="Front page carosoul" />
      </div>
      <div className="container mt-4 px-20">
        <div className="row">
          <div className="col-12 background">
            <h1 className="text-center mt-4 text-greens h1">
              RESONITES OF INDORE STUDY CENTRE ARE NOW SHINING STARS OF VARIOUS
              IITS
            </h1>
          </div>
        </div>
      </div>
      <JEEResultSection />
      <div className="container mt-4 px-24">
        <div className="row">
          <div className="col-12 backgrounds">
            <h1 className="text-center mt-4 text-greens h1">
              <b>NEET UG Results</b>
              <br />
              Indore Resonites are shining in Govt Medical Colleges
            </h1>
          </div>
        </div>
      </div>
      <div className="container result mt-4 mb-4">
        <NEETResultSection />
      </div>
      <div className="d-flex justify-content-center"></div>
    </div>
  );
}

export default ResultPage;
