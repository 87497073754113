import React from "react";
import backGroundImage from "../../assets/image/vipin_sir_session_photo.jpg";
import studyRoom from "../../assets/image/Spesial Self Study Rooms-1.jpg";
import indivisualCouncilling from "../../assets/image/individual counsling session.jpg";
import teacherDay from "../../assets/image/teachersDay.jpg";
import festivalCelebration from "../../assets/image/Celebrating Festivals.jpg"
import picknic from "../../assets/image/Picnic Day.jpg"
import childrenDay from "../../assets/image/childrenDay.jpg"

const GallerySection = () => {
  return (
    <section
      className="large-padding Resonance-background-parallax"
      style={{ backgroundImage: `url(${backGroundImage})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-auto align-center">
            <header className="Resonance-module Resonance-heading heading--white">
              <div className="title-text-wrap">
                <h2 className="heading-title h2 bg-indigo-600 shadow-xl px-5 py-2 rounded-xl ">Life at Resonance</h2>
              </div>
              <div className="heading-decoration" />
            </header>
          </div>
        </div>
        <div className="row justify-content-center popup-gallery">
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-4 element-anime-fadeInUp-js">
            <div className="resonance-module resonance-case-item">
              <a
                href="#"
                className="case-item-thumb js-popup-image galleryItem "
                data-group={2}
              >
                <img
                  loading="lazy"
                  className="curvedImg"
                  src={studyRoom}
                  alt="Learning By Doing"
                />
                <div className="case-item-content h6 case-item-title">
                  Special Self Study Rooms
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-4 element-anime-fadeInUp-js">
            <div className="resonance-module resonance-case-item">
              <a
                href="#"
                className="case-item-thumb js-popup-image galleryItem "
                data-group={9}
              >
                <img
                  loading="lazy"
                  className="curvedImg"
                  src={indivisualCouncilling}
                  alt="Career Guidance"
                />
                <div className="case-item-content h6 case-item-title">
                  Individual Counselling Sessions
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-4 element-anime-fadeInUp-js">
            <div className="resonance-module resonance-case-item">
              <a
                href="#"
                className="case-item-thumb js-popup-image galleryItem "
                data-group={16}
              >
                <img
                  loading="lazy"
                  className="curvedImg"
                  src={teacherDay}
                  alt="Meditation Session"
                />
                <div className="case-item-content h6 case-item-title">
                  Teachers Day Celebration
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-4 element-anime-fadeInUp-js">
            <div className="resonance-module resonance-case-item">
              <a
                href="#"
                className="case-item-thumb js-popup-image galleryItem "
                data-group={3}
              >
                <img
                  loading="lazy"
                  className="curvedImg"
                  src={festivalCelebration}
                  alt="Physical Activity"
                />
                <div className="case-item-content h6 case-item-title">
                  Celebrating Festivals
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-4 element-anime-fadeInUp-js">
            <div className="resonance-module resonance-case-item">
              <a
                href="#"
                className="case-item-thumb js-popup-image galleryItem "
                data-group={7}
              >
                <img
                  loading="lazy"
                  className="curvedImg"
                  src={picknic}
                  alt="Therapist Session"
                />
                <div className="case-item-content h6 case-item-title">
                  Picnic Day
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-4 element-anime-fadeInUp-js">
            <div className="resonance-module resonance-case-item">
              <a
                href="#"
                className="case-item-thumb js-popup-image galleryItem "
                data-group={5}
              >
                <img
                  loading="lazy"
                  className="curvedImg"
                  src={childrenDay}
                  alt="Yoga & Meditation"
                />
                <div className="case-item-content h6 case-item-title">
                  {" "}
                  Celebrating Children's Day
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySection;
