import React, { useState } from "react";
import { axiosClient } from "../webServices/axiosInstance";
import axios from "axios";
import Notiflix from "notiflix";
import backImg from "../assets/register-back.jpg";
import Thankyou from "../component/Thankyou";

function ResosatRegistrationForm() {
  const [name, setName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [studentClass, setStudentClass] = useState(null);
  const [city, setCity] = useState(null);
  const [school, setSchool] = useState(null);
  const [registerStatus, setRegisterStatus] = useState(false);

  async function registerStudent(userInfo) {
    try {
      const response = new Promise(async (resolve, reject) => {
        const register = await axiosClient().post(
          "/resosat/register",
          userInfo
        );
        console.log(register);
        resolve(register);
      });
      const data = await response;
      console.log(data);
      return data;
    } catch (error) {
      console.log(error.message);
      return { status: false };
    }
  }

  async function sendMessage(usernumber, userID, userpassword) {
    try {
      let smsAPI = `https://dudusms.in/smsapi/send_sms?authkey=di2EgIdepIYbvcR752uNwL&mobiles=${usernumber}&message=Dear Student, You are successfully registered for Resonance Indore’s RESOSAT- The Biggest Scholarship Exam. Pls save login ID ${userID} and Password ${userpassword} Regards Resonance Indore&sender=RESOIN&route=4&templetid=1707172648098887977`;
      const response = await axios.get(smsAPI);
      return response;
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      name: name,
      mobile: mobile,
      class: studentClass,
      city: city,
      school: school,
    };
    Notiflix.Loading.circle();
    const userregistered = await registerStudent(data);

    if (userregistered.data.status) {
      const message = sendMessage(
        userregistered.data.data.mobile,
        userregistered.data.data.userId,
        userregistered.data.data.password
      );
      
      setTimeout(() => {
        Notiflix.Loading.remove();
        Notiflix.Notify.success("user registered successfully");
        setRegisterStatus(true);
      }, 1500);
    } else {
      Notiflix.Notify.failure(
        `Registration failed : ${userregistered.data.message}`
      );
    }
  };

  return (
    <>
      <div
        className="flex  items-center justify-center   h-screen"
        style={{ backgroundImage: `url('${backImg}')` }}
      >
            <form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                    className="rounded-lg shadow-md bg-indigo-600 py-8 px-14"
                  >
                    <div className="mb-4">
                      <h1 className="text-white flex justify-center font-bold text-3xl mb-5">
                        REGISTER HERE
                      </h1>
                      <label
                        for="name"
                        className="block  text-xl font-medium text-white"
                      >
                        Student Name
                      </label>
                      <input
                        type="text"
                        required
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        id="name"
                        className="mt-1 focus:text-white capitalize block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="*Enter full Name"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        for="city"
                        className="block text-xl font-medium text-white"
                      >
                        City
                      </label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        required
                        id="city"
                        className="mt-1 capitalize focus:text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="*Enter your city"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        for="school"
                        className="block text-xl font-medium text-white"
                      >
                        School
                      </label>
                      <input
                        type="text"
                        required
                        onChange={(e) => {
                          setSchool(e.target.value);
                        }}
                        id="school"
                        className="mt-1 capitalize focus:text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="*Enter your School"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        for="class"
                        className="block text-xl font-medium text-white"
                      >
                        class
                      </label>
                      <select
                        type="text"
                        onChange={(e) => {
                          setStudentClass(e.target.value);
                        }}
                        id="class"
                        required
                        className="mt-1 focus:text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="*Enter your city"
                      >
                        <option className="text-black">
                          --select class--
                        </option>
                        <option className=" text-black" value="8th">
                          8<sup>th</sup>
                        </option>
                        <option className=" text-black" value="9th">
                          9<sup>th</sup>
                        </option>
                        <option className=" text-black" value="10th">
                          10<sup>th</sup>
                        </option>
                        <option className=" text-black" value="11th">
                          11<sup>th</sup>
                        </option>
                        <option className=" text-black" value="12th">
                          12<sup>th</sup>
                        </option>
                        <option className=" text-black" value="dropper">
                          dropper
                        </option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label
                        for="number"
                        className="block text-xl font-medium text-white"
                      >
                        Number
                      </label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                        id="number"
                        required
                        className="mt-1 focus:text-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        placeholder="*Enter mobile Number"
                      />
                      <p className="mt-2 text-lg text-white ">
                        Note : Only one user can register with one mobile number
                      </p>
                    </div>
                    {/* <div>
                      <p className="font-semibold text-white">
                        Already Registered?{" "}
                        <span className="underline cursor-pointer text-orange-600">
                          click here to Login
                        </span>{" "}
                      </p>
                    </div> */}

                    <button
                      type="submit"
                      className=" justify-center mt-2 w-full py-2 px-8 border hover:shadow-xl duration-200 hover:scale-95 hover:bg-[#82ac18] hover:text-white border-transparent shadow-sm text-xl font-bold rounded-md text-black bg-[#f6f6f6] "
                    >
                      Submit
                    </button>
                  </form>
      </div>
      {registerStatus && (
        <div className="h-screen z-[10000] flex fixed top-0 w-screen bg-[#1a1a1896] z-50">
          <Thankyou
            message={`You have been registered successfully. your UserId and Password will be sent on given mobile number shortly !`}
          />
        </div>
      )}
    </>
  );
}

export default ResosatRegistrationForm;
