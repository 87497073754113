import React, { useState, useRef, useEffect } from 'react';
import { axiosClient } from '../webServices/axiosInstance';
import Notiflix from 'notiflix';

export default function OtpVerification({success,setSuccess,mobile}) {
  const [otp, setOtp] = useState(['', '', '', '',]);
  const [error, setError] = useState('');
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (index, value) => {
    if (isNaN(Number(value))) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== '' && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, 4).split('');
    const newOtp = [...otp];
    pastedData.forEach((value, index) => {
      if (index < 4 && !isNaN(Number(value))) {
        newOtp[index] = value;
      }
    });
    setOtp(newOtp);
    inputRefs.current[Math.min(pastedData.length, 4)]?.focus();
  };

  const verifyOtp = async() => {
    const enteredOtp = otp.join('');
    if (enteredOtp.length !== 4) {
      setError('Please enter a 4-digit OTP');
      return;
    }
    Notiflix.Loading.circle()
    let verified  = await axiosClient().post("/resosat/verify",{mobile:mobile,otp:enteredOtp})
    if (verified.data.status) {
        setSuccess(true);
        setError(null);
        Notiflix.Loading.remove()
    }else{
        setSuccess(false)
        setError('Invalid OTP . please try again')
        Notiflix.Loading.remove()
    }

    // Simulating OTP verification
    // if (enteredOtp === '123456') {
    //   setSuccess(true);
    //   setError('');
    // } else {
    //   setError('Invalid OTP. Please try again.');
    // }
  };
  console.log(error)
  console.log(otp.join(""))
  return (
    <div className="flex flex-col items-center justify-center lg:p-24   bg-gray-100">
      <div className="p-8 bg-white rounded-lg shadow-md w-96">
        <h2 className="text-2xl font-bold mb-6 text-center">OTP Verification</h2>
        <p className="text-sm text-gray-600 mb-6 text-center">
          Enter the 4-digit code sent to your device
        </p>
        <div className="flex justify-between mb-6">
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              className="w-12 h-12 text-center text-xl font-bold border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={handlePaste}
            />
          ))}
        </div>
        {error && <p className="text-red-500 text-sm mb-4 text-center">{error}</p>}
        {success==="success" && (
          <p className="text-green-500 text-sm mb-4 text-center">OTP verified successfully!</p>
        )}
        <button
          className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={verifyOtp}
          disabled={success==="success"}
        >
          Verify OTP
        </button>
        <h1 className='mt-3'>note :Incase if you are not receiving OTP <a className='text-[blue!important] font-bold'  href="https://wa.me/7389934555?text=register%20me%20to%20Resosat." target="_blank"><ins>click here</ins></a> and share details To register via whatsapp</h1>
        <p className='text-yellow-600 font-bold mt-3'>details includes (student Name , mobile , class ,school,mode:online or offline )</p>
        {/* <button className='underline text-blue-600 text-center mt-4 mx-auto float-right hover:text-blue-300'  >Re-send OTP </button> */}
      </div>
    </div>
  );
}
