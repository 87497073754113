import React from "react";
import MissionImage from "../../assets/image/31-10-2022-02.jpg"

const MissionSection = () => {
  return (
    <section className="mis_vis_val d-flex justify-content-center">
      <img
        loading="lazy"
        src={MissionImage}
        style={{ width: "80%" }}
      />
    </section>
  );
};

export default MissionSection;
