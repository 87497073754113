import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../component/NavBar";
import Slider from "../component/Slider";
import CourseCardSection from "../component/sections/CourseCardSection";
import ResultCardSection from "../component/sections/ResultCardSection";
import GallerySection from "../component/sections/GallerySection";
import Footer from "../component/Footer";
import StudentCommentSection from "../component/sections/StudentCommentSection";

const HomePage = () => {
  return (
    <>
     
      <Slider />
      <CourseCardSection />
      <ResultCardSection />
      <GallerySection />
      <StudentCommentSection/>
    </>
  );
};

export default HomePage;
